import React from "react";
import Layout from "../components/shared/Layout";
import AboutUsContent from "../components/about-us/AboutUsContent";
import PageHeader from "../components/shared/PageHeader";
import { useStaticQuery, graphql } from "gatsby";

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
        frontmatter {
          header {
            description
          }
        }
      }
    }
  `);
  return (
    <Layout
    seoTitle="About Us"
    seoKeywords="JFE Engineering Malaysia, JFEM, JFEM Director, Energy, Environment, General Construction, SDG, EPC Company in Malaysia, Best EPC Company in Malaysia, Japanese EPC Company in Malaysia, Japanese quality, JFEM Quality Policy, JFEM Health Policy, JFEM Safety Policy, JFEM Environmental Policy, JFE Engineering Policy, JFEM Certifications, JFE Engineering Malaysia Certifications"
    >

      <PageHeader
        pageTitle="About us"
        pageDescription={data?.markdownRemark.frontmatter.header?.description}
      />
      <AboutUsContent />
    </Layout>
  );
};

export default AboutUs;
