import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Row } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import CircleBg from "../../../static/svg/Large-Circle.svg"
import { GradientFooterBg } from "../../components/shared/Footer"
import {
  Heading24,
  Heading32,
  Heading40,
  Paragraph18,
} from "../../styles/PageStyles"
import {
  StyledSlider,
  AbousUsContainer,
  GreetingsMalaysia,
  GreetingsHQ,
  OurHistory,
  QhseRow,
  Qhse,
  HistoryLayout,
  HistoryStart,
  HistoryContent,
  HistoryItem,
  HistoryYear,
  HistoryDetail,
  HistoryCurrent,
  HistoryCurrentInner,
  CarouselItem,
  Today,
  StyledMarkdown,
  StyledDescription,
} from "./AboutUsContent.styled"

export const Carousel = ({ children }) => {
  function Count() {
    let count = 0
    children?.map((child) => !child && count++)

    const childrenCount = children?.length - count

    return childrenCount > 1
  }

  const carouselSettings = {
    dots: true,
    infinite: Count(),
    focusOnSelect: true,
    slidesToShow: Count() ? 2 : 1,
    slidesToScroll: Count() ? 2 : 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          infinite: true,
          focusOnSelect: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return <StyledSlider {...carouselSettings}>{children}</StyledSlider>
}

const AboutUsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
        frontmatter {
          greetings {
            hidden
            header
            presidentDirector {
              hidden
              title
              name
              jobTitle
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              body {
                paragraph
              }
            }
            ceo {
              hidden
              title
              name
              jobTitle
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              body {
                paragraph
              }
            }
          }
          JFEHistory {
            current
            previous
            history {
              date
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: BLURRED
                  )
                }
              }
              showyearonly
              type
            }
          }
          qhse {
            title
            body {
              paragraph
            }
            backgroundImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          certificates {
            title
            hidden
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          qhsePolicies {
            title
            hidden
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { presidentDirector } = frontmatter.greetings
  const { ceo } = frontmatter.greetings
  const history = frontmatter.JFEHistory.history

  console.log(history)
  const historyArray = history?.map((obj) => {
    return { ...obj, date: new Date(obj.date) }
  })
  // sorted desc
  const sortedHistory = historyArray?.sort(
    (objA, objB) => Number(objB.date) - Number(objA.date)
  )

  //sorted asc
  // const sortedHistory = historyArray?.sort(
  //   (objA, objB) => Number(objA.date) - Number(objB.date)
  // );

  const slicedHistory = sortedHistory.slice(0, 7)
  const [sliced, setSliced] = useState(true)

  let histories = sliced ? slicedHistory : sortedHistory

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0")
  }
  const formatDate = (date) => {
    return [
      padTo2Digits(date.getFullYear()),
      padTo2Digits(date.getMonth() + 1),
    ].join(".")
  }

  return (
    <AbousUsContainer fluid>
      {!frontmatter.greetings?.hidden && (
        <>
          <Row id="greetings" className="with-margin ">
            <Heading40>{frontmatter.greetings?.header}</Heading40>
            {!presidentDirector?.hidden && (
              <GreetingsMalaysia>
                <div className="greeting-image">
                  <GatsbyImage
                    image={getImage(presidentDirector?.image)}
                    alt={`${presidentDirector?.name}, ${presidentDirector?.jobTitle}`}
                    className="greeting-image_inner"
                  />
                </div>
                <div className="greeting-content">
                  <Heading24>{presidentDirector?.title}</Heading24>
                  <Paragraph18>
                    {presidentDirector?.body?.map((message, index) => (
                      <ReactMarkdown key={index}>
                        {message?.paragraph}
                      </ReactMarkdown>
                    ))}
                  </Paragraph18>
                </div>
                <div className="greeting-title">
                  <Heading24>{presidentDirector?.name}</Heading24>
                  <Paragraph18>{presidentDirector?.jobTitle}</Paragraph18>
                </div>
              </GreetingsMalaysia>
            )}
          </Row>
          <Row className="with-margin pt-100 pb-100">
            {!ceo?.hidden && (
              <GreetingsHQ>
                <div className="greeting-image">
                  <GatsbyImage
                    image={getImage(ceo?.image)}
                    alt={`${ceo?.name}, ${ceo?.jobTitle}`}
                    className="greeting-image_inner"
                  />
                </div>
                <div className="greeting-content">
                  <Heading24>{ceo?.title}</Heading24>
                  <Paragraph18>
                    {ceo?.body?.map((message, index) => (
                      <ReactMarkdown key={index}>
                        {message?.paragraph}
                      </ReactMarkdown>
                    ))}
                  </Paragraph18>
                </div>
                <div className="greeting-title">
                  <Heading24>{ceo?.name}</Heading24>
                  <Paragraph18>{ceo?.jobTitle}</Paragraph18>
                </div>
              </GreetingsHQ>
            )}
          </Row>
        </>
      )}
      <Row id="history" className="with-margin pb-100">
        <OurHistory>
          <HistoryLayout>
            <HistoryStart fluid>
              <Heading32>Our History</Heading32>
            </HistoryStart>

            <Today>
              Today <br />
              <StyledMarkdown className="title">
                {frontmatter?.JFEHistory?.current}
              </StyledMarkdown>
            </Today>

            <HistoryContent>
              {histories.map((item, index) => {
                return (
                  <HistoryItem key={index}>
                    <div
                      className={`history_item_inner ${
                        item.type === "extension" ? "extension" : ""
                      }`}
                    >
                      <HistoryYear
                        data-aos="zoom-in"
                        className={item.type === "breakpoint" ? "hidden" : ""}
                      >
                        <p>
                          {item.showyearonly
                            ? item.date.getFullYear()
                            : formatDate(item.date)}
                        </p>
                      </HistoryYear>
                      <HistoryDetail
                        data-aos="fade-left"
                        data-aos-delay="700"
                        className={
                          item.type === "breakpoint" ? "HistoryBreakpoint" : ""
                        }
                      >
                        <StyledDescription>
                          {item.description}
                        </StyledDescription>
                        {item.image ? (
                          <GatsbyImage
                            className="historyImage"
                            image={getImage(item?.image)}
                            alt=""
                          />
                        ) : null}
                      </HistoryDetail>
                    </div>
                  </HistoryItem>
                )
              })}

              <HistoryCurrent>
                <HistoryCurrentInner>
                  {sliced ? (
                    <button
                      onClick={() => {
                        setSliced(false)
                      }}
                    >
                      View More
                    </button>
                  ) : (
                    <>
                      <StyledMarkdown className="title">
                        {frontmatter?.JFEHistory?.previous}
                      </StyledMarkdown>

                      <button
                        onClick={() => {
                          setSliced(true)
                        }}
                      >
                        View Less
                      </button>
                    </>
                  )}
                </HistoryCurrentInner>
              </HistoryCurrent>
            </HistoryContent>
          </HistoryLayout>
        </OurHistory>
      </Row>

      <QhseRow id="qhse" className="with-margin pb-100">
        <Qhse>
          <Heading32>{frontmatter.qhse?.title}</Heading32>
        </Qhse>
        <div className="terms-wrapper">
          <Col className="terms">
            {frontmatter.qhse?.body.map((item, index) => (
              <Paragraph18 key={index}>
                <ReactMarkdown>{item.paragraph}</ReactMarkdown>
              </Paragraph18>
            ))}
          </Col>
          <Col className="qhse-bg-wrapper">
            <GatsbyImage
              className="qhse-bg-img"
              image={getImage(frontmatter.qhse?.backgroundImage)}
              alt="Quality, Health, Safety and Environmental Policy"
            />
          </Col>
        </div>
      </QhseRow>
      <Row id="policies" className="certificates">
        <Carousel>
          {frontmatter.qhsePolicies?.map(
            (policy, index) =>
              !policy.hidden && (
                <div key={index}>
                  <CarouselItem>
                    <GatsbyImage
                      image={getImage(policy?.image)}
                      alt={policy?.title}
                      draggable={false}
                    />
                    <Heading24>{policy?.policy}</Heading24>
                  </CarouselItem>
                </div>
              )
          )}
        </Carousel>
        <CircleBg className="circle-bg right" />
      </Row>
      <div className="horizontal-border" />
      <Row className="certificates">
        <Heading32>Certificates</Heading32>
        <Carousel>
          {frontmatter.certificates?.map(
            (certificate, index) =>
              !certificate.hidden && (
                <div key={index}>
                  <CarouselItem>
                    <GatsbyImage
                      image={getImage(certificate?.image)}
                      alt={certificate?.title}
                      draggable={false}
                    />
                    <Heading24>{certificate?.title}</Heading24>
                  </CarouselItem>
                </div>
              )
          )}
        </Carousel>
        <CircleBg className="circle-bg" />
      </Row>
      <GradientFooterBg />
    </AbousUsContainer>
  )
}

export default AboutUsContent
