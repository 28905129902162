import styled from "styled-components";
import { Row, Container, Col } from "react-bootstrap";
import Slider from "react-slick";
import dot from "../../multimedia/dot-bg.png";
import { Paragraph18 } from "../../styles/PageStyles";
import ReactMarkdown from "react-markdown";

export const AbousUsContainer = styled(Container)`
  padding: 0;
  background: #f7f8fc;
  position: relative;
  z-index: 1;

  h1,
  p {
    margin-bottom: 25px;
  }

  h1 {
    text-align: center;
  }

  li {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.06em;
    color: #5b5d5e;

    @media only screen and (max-width: 769px) {
      font-size: 16px;
    }
  }

  .horizontal-border {
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, #aac532 -11.34%, #d1e869 84.35%);
  }

  .certificates {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 60px 10% 75px 10%;

    h1 {
      text-align: center;
    }

    .circle-bg {
      position: absolute;
      bottom: -600px;
      left: -900px;
      z-index: -1;

      &.right {
        bottom: -600px;
        left: 900px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .pt-100 {
      padding-top: 50px;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    p,
    li {
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 400;
      letter-spacing: 0.06em;
    }
  }
`;

export const QhseRow = styled(Row)`
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .terms-wrapper {
    display: flex;
    padding: 0;

    strong {
      color: #149be1;
    }

    .terms {
      flex-basis: 60%;
      margin-right: 26px;
      p,
      li {
        text-align: justify;
      }
    }

    .qhse-bg-wrapper {
      .gatsby-image-wrapper {
        box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.07);
        border-radius: 10px;

        @media only screen and (min-width: 1441px) {
          width: 500px;
        }
      }

      @media only screen and (min-width: 1366px) and (max-width: 1440px) {
        height: 0;

        .gatsby-image-wrapper {
          width: 600px;
        }
      }
    }

    @media only screen and (max-width: 1365px) {
      display: flex;
      flex-direction: column;

      margin-left: 0px;
      .terms {
        width: 100%;
        margin-right: 0px;
      }
    }
  }

  .circle-bg {
    position: absolute;
    right: -570px;
    bottom: -570px;
    z-index: -2;
  }
`;

export const GreetingsMalaysia = styled(Col)`
  position: relative;
  border: 2px solid #149be1;
  border-radius: 20px;
  padding: 0;
  margin-left: 150px;

  .greeting-image {
    position: absolute;
    top: 15%;
    left: -150px;
    border-radius: 50%;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: 12px solid white;

    .gatsby-image-wrapper {
      width: 272px;
    }

    @media only screen and (max-width: 1366px) {
      left: -125.5px;

      .gatsby-image-wrapper {
        width: 232px;
      }
    }

    @media only screen and (max-width: 1024px) {
      position: absolute;
      top: 0;
      margin: 25px 0 25px 50%;
    }

    @media only screen and (max-width: 425px) {
      left: -125px;
    }
  }

  .greeting-content {
    margin-left: 175px;
    padding: 50px 50px 50px 0;

    h1 {
      font-weight: bold;
      text-align: left;
      white-space: nowrap;
    }

    p {
      text-align: justify;
      margin-bottom: 25px !important;
      &:last-child {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1388px) and (min-width: 1025px) {
      padding: 50px 50px 50px 175px;
      margin: 0;

      h1 {
        margin-left: -125px;
        white-space: normal;
        text-align: center;
      }
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 0 10% 10% 10%;
      margin-top: 300px;

      h1 {
        white-space: normal;
        text-align: center;
      }
    }
  }

  .greeting-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 175px;
    background: linear-gradient(180deg, #149be1 0%, #0073af 100%);
    border-radius: 0 0 20px 20px;
    border: 3px solid #fff;

    h1 {
      color: #ffffff;
      text-align: left;
      margin: 0;
    }

    p {
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      margin: 0;
    }

    @media only screen and (max-width: 1024px) {
      padding: 10px 0;

      h1,
      p {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    margin: 0;
  }
`;

export const GreetingsHQ = styled(Col)`
  position: relative;
  border: 2px solid #d1e869;
  border-radius: 20px;
  padding: 0;
  margin-right: 150px;

  .greeting-image {
    position: absolute;
    top: 15%;
    right: -150px;
    border-radius: 50%;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: 12px solid white;

    .gatsby-image-wrapper {
      width: 272px;
    }

    @media only screen and (max-width: 1366px) {
      right: -125.5px;

      .gatsby-image-wrapper {
        width: 232px;
      }
    }

    @media only screen and (max-width: 1024px) {
      top: 0;
      margin: 25px 50% 25px 0;
    }

    @media only screen and (max-width: 425px) {
      right: -125px;
    }
  }

  .greeting-content {
    margin-right: 175px;
    padding: 50px 0 50px 50px;

    h1 {
      font-weight: bold;
      text-align: right;
      color: #aac532;
      white-space: nowrap;
    }

    p {
      text-align: justify;
      margin-bottom: 25px !important;
      &:last-child {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1388px) and (min-width: 1025px) {
      padding: 50px 175px 50px 50px;
      margin: 0;
      margin-top: 0;

      h1 {
        margin-right: -125px;
        white-space: normal;
        text-align: center;
      }
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 0 10% 10% 10%;
      margin-top: 300px;

      h1 {
        white-space: normal;
        text-align: center;
      }
    }
  }

  .greeting-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 175px;
    background: linear-gradient(180deg, #d1e869 0%, #aac532 100%);
    border-radius: 0 0 20px 20px;
    border: 3px solid #fff;

    h1 {
      text-align: right;
      color: #ffffff;
      margin: 0;
    }

    p {
      font-weight: 500;
      color: #ffffff;
      text-align: right;
      margin: 0;
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 10px 0;

      h1,
      p {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    margin: 0;
  }
`;

export const OurHistory = styled(Col)`
  padding: 0;
  h1 {
    font-weight: bold;
    text-align: left;

    * {
      color: #0073af;
      font-weight: bold;
    }

    /* @media only screen and (max-width: 1024px) {
      text-align: center !important;
    } */
  }

  p {
    text-align: justify;
  }
`;

export const Qhse = styled(Col)`
  margin: 0 10%;
  padding: 0;

  h1 {
    text-align: center;

    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  p {
    text-align: justify;
    margin-bottom: 25px !important;
  }
`;

export const StyledSlider = styled(Slider)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 25px 0;

  .slick-list {
    width: 100%;
  }

  .slick-slide {
    margin-bottom: 35px;
  }

  .slick-slide img {
    object-fit: contain !important;
  }

  .slick-dots {
    li button:before {
      display: none;
    }

    .slick-active {
      button {
        background: #0073af;
      }
    }

    button {
      width: 18px;
      height: 18px;
      background: #bcc1c3;
      border-radius: 50%;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .slick-list {
      width: 60%;
      margin: 0 auto;
    }
  }
`;

export const CarouselItem = styled.div`
  width: 500px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 25px;

  .gatsby-image-wrapper {
    height: 600px;
  }

  img {
    border-radius: 10px 10px 0 0;
  }

  h1 {
    margin: 0;
    font-weight: bold;
  }

  @media only screen and (min-width: 1024px) {
    width: 38vw;
    max-width: 500px;

    .gatsby-image-wrapper {
      height: 40vw;
      max-height: 600px;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 80vw;
    padding: 10px;

    .gatsby-image-wrapper {
      height: 90vw;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    width: 280px;
    padding: 15px;

    .gatsby-image-wrapper {
      height: 320px;
    }
  }
`;
export const StyledLinks = styled.ul`
  li {
    list-style: none;
    color: #149be1;
    a {
      color: #149be1;
      font-weight: 400;
    }

    &::before {
      content: "-";
      color: #149be1;
    }
  }
`;

export const StyledParagraph2 = styled.div`
  margin-bottom: 0;
  p {
    color: #149be1;
    font-weight: 700;
    margin-bottom: 0;
  }
`;

export const StyledParagraph = styled(Paragraph18)`
  z-index: 10;
  p {
    color: #149be1;
    font-weight: 700;
  }
`;

export const StyledDescription = styled(ReactMarkdown)`
  p {
    font-family: Montserrat;
    font-weight: 400;
    letter-spacing: 0.06em;
    margin: 0 !important;
    max-width: 80ch;
  }

  @media only screen and (min-width: 1441px) {
    font-size: 18px;

    p {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 14px;

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1365px) {
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }
`;
export const StyledMarkdown = styled(ReactMarkdown)`
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0.06em;
  color: #149be1;

  &.title p {
    font-size: 24px !important;
    color: #149be1;
    font-weight: 700;
  }

  @media only screen and (min-width: 1441px) {
    font-size: 18px;

    p {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 14px;

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1365px) {
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }
`;
export const Today = styled.p`
  padding: 0 20px;
  position: relative;
  background-color: #f7f8fc;
  z-index: 100;
  color: #149be1;
  font-weight: 700;

  @media only screen and (max-width: 768px) {
    padding: 0 5px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    height: 20px;
    width: 100%;
    //background-color: black;
    z-index: 5;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.07326680672268904) 0%,
      rgba(247, 248, 252, 1) 60%
    );
  }
`;

export const HistoryLayout = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const HistoryStart = styled(Container)`
  height: 100px;
  width: 100%;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 100;
  width: 100%;
  background-color: #f7f8fc;
  //background: linear-gradient(180deg, #149be1 0%, #0073af 100%);
  margin-left: 0;
  margin-right: 0;
`;
export const HistoryContent = styled.div`
  vertical-align: baseline;
  border: 0;
  outline: 0;
  font-size: 100%;
  width: 100%;
  height: max-content;
  padding-block: 2px;
  display: flex;
  flex-direction: column;
  //position: relative;
`;

export const HistoryItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  margin-left: 0;
  margin-right: 0;

  a {
    color: #149be1;
    font-weight: 700;
  }

  &:first-child {
    & .history_item_inner {
      &::after {
        top: -20px;
      }
    }
  }
  &:nth-last-child(2) {
    & .history_item_inner {
      &::after {
        bottom: -60px;
      }
    }
  }

  &:has(.extension) {
    top: 42px;
    left: 0;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 30px;
      bottom: 5px;
      left: 54px;
      //background-image: url(${dot});
      //background-repeat: repeat-y;
      width: 5px;
      z-index: -1;
      background-color: #c9e15e;
    }

    @media only screen and (max-width: 768px) {
      &::after {
        left: 49px;
      }
    }
  }

  & .history_item_inner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 25px 0;
    position: relative;

    &.extension {
      padding-left: 120px;
      &::after {
        content: "";
        position: absolute;
        top: 42px;
        left: 120px;
        height: 5px;
        transform: translate(-100%);
        //background-image: url(${dot});
        //background-repeat: repeat-y;
        width: 80px;
        z-index: 1;
        background-color: #c9e15e;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 30px;
      bottom: -35px;
      left: 39px;
      //background-image: url(${dot});
      //background-repeat: repeat-y;
      width: 5px;
      z-index: 1;
      background-color: #c9e15e;
    }

    @media only screen and (max-width: 768px) {
      &.extension {
        padding-left: 80px;
        flex-wrap: wrap;
        row-gap: 20px;
        gap: 20px;

        &::after {
          left: 115px;
        }
      }

      &::after {
        left: 34px;
      }
    }
  }
`;

export const HistoryYear = styled.div`
  background: linear-gradient(180deg, #149be1 0%, #0073af 100%);
  margin-right: 30px;
  position: relative;
  flex-shrink: 0;
  z-index: 2;
  border-radius: 10px;
  padding: 20px 36px;

  &.hidden {
    display: none;
  }

  p {
    padding: 0;
    margin: 0;
    color: #ffffff;
    display: grid;
    place-content: center;
    position: absolute;
    inset: 0;
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
    margin-right: 80px;
    padding: 22px 42px;
  }
`;

export const HistoryDetail = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .historyImage {
    max-width: 418px;
    width: 100%;
    height: auto;
    margin-top: 10px;
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    object-fit: contain;
    aspect-ratio: 4/3;
  }

  &.HistoryBreakpoint {
    z-index: 10;
    padding: 22px 0;
    background-color: #f7f8fc;
    position: relative;

    p {
      margin: 0;
      strong {
        color: #149be1;
        font-size: 24px;
      }
    }
    & ul {
      list-style: none;
      color: #149be1;

      li a > strong {
        color: #149be1;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 40px;
      bottom: 0;
      transform: translateY(50%);
      z-index: 10;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.07326680672268904) 0%,
        rgba(247, 248, 252, 1) 60%
      );
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 40px;
      transform: translateY(-50%);
      z-index: 10;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.07326680672268904) 0%,
        rgba(247, 248, 252, 1) 60%
      );
    }
  }
`;
export const HistoryCurrent = styled.div`
  height: 100px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0 16px;
  position: relative;
  padding-top: 60px;
  background: #f7f8fc;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.07326680672268904) 0%,
      rgba(247, 248, 252, 1) 60%
    );
  }

  p {
    font-weight: bold;

    width: 80px;
    color: #000000;
    font-weight: 500;
    white-space: nowrap;
  }
`;

export const HistoryCurrentInner = styled.div`
  button {
    outline: none;
    border: none;
    background-color: #149be1;
    color: white;
    border-radius: 20px;
    padding: 8px 16px;
    margin: 0 auto;
    display: block;
  }
  p {
    color: #149be1;
    font-weight: 700;
    pointer-events: none;
    cursor: pointer;
  }
`;
